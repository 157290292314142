<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>코드관리</h1></div>
    <common-area-search
        :row="1"
        :colspan="1"
        @search="commonOnChangeEvent"
        @clear="cleanSearchDataEvent"
    >
      <template v-slot:R1_C1>
        <label>그룹코드 </label>
        <ul>
          <input
            ref="compoundTextRef"
            class="k-textbox w100"
            placeholder="코드명을 입력하세요"
            @keyup.enter="commonOnChangeEvent"
          />
        </ul>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->

    <section>
      <div class="box_flex mgt10">
        <div>
      <!-- // start : 그룹 코드목록 -->
      <div class="contents_top_sec">
        <div class="c_t_button_wrap">
          <h2>그룹 코드
            <button class="mid_btn orange mgl10" @click="e => {
                this.uiData.cdGrupSaveMode = 'NEW'
                this.uiData.cdGrupSaveData = {}
                this.$refs.cdGrupSaveWindowRef.widget().open()
            }">추가</button>
          </h2>
        </div>
      </div>
      
          <div class="content_list">
            <kendo-grid ref="mstGridRef"
                        style="cursor: pointer"
                        :data-source="computedMstGridRows"
                        :navigatable="false"
                        :sortable-mode="'multiple'"
                        :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
                        :reorderable="true"
                        :column-menu="false"
                        :resizable="true"
                        :selectable="'row'"
                        :columns="mstGridColumns"
                        :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
                        @change="onChangeMstGrid"
            />
          </div>
        </div>
      <!-- // end : 그룹 코드목록 -->

      <!-- // start : 코드목록 -->
      <div>
        <div class="contents_top_sec">
          <div class="c_t_button_wrap">
            <h2>코드
              <button class="mid_btn orange mgl10" @click="e => {
                this.uiData.cdIdSaveMode = 'NEW'
                this.uiData.cdIdSaveData = { cdGrup: this.uiData.cdGrupSelected }
                this.$refs.cdIdSaveWindowRef.widget().open()
              }">추가</button>
            </h2>
          </div>
        </div>
        <div class="content_list">
          <!-- Kendo 영역 -->
          <kendo-grid ref="dtlGridRef"
                      style="cursor: pointer"
                      :data-source="computedDtlGridRows"
                      :navigatable="false"
                      :sortable-mode="'multiple'"
                      :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
                      :reorderable="true"
                      :column-menu="false"
                      :resizable="true"
                      :selectable="'row'"
                      :columns="dtlGridColumns"
                      :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
          />
          </div>
        <!-- Kendo 영역 끝 -->
      </div>
      </div>
      <!-- // end : 코드목록 -->
    </section>

    <!-- // 그룹코드 저장 window -->
    <code-master-save-window  ref="cdGrupSaveWindowRef"
                              :mode="uiData.cdGrupSaveMode"
                              v-model="uiData.cdGrupSaveData"
                              @close="commonOnChangeEvent"></code-master-save-window>

    <!-- // 코드 저장 window -->
    <code-detail-save-window  ref="cdIdSaveWindowRef"
                              :mode="uiData.cdIdSaveMode"
                              v-model="uiData.cdIdSaveData"
                              @close="fetchDtlGridDataSource"></code-detail-save-window>
  </div>
</template>

<script>
import EtnersEfsUtil from '@/common/etners.efs.util'
import ApiConfig from '@/api/api.config'

export default {
  name: 'CodeManageList',
  components: {
    'CodeMasterSaveWindow': () => import('@/components/CodeMasterSaveWindow'),
    'CodeDetailSaveWindow': () => import('@/components/CodeDetailSaveWindow'),
  },
  computed: {
    computedMstGridRows: function () {
      const vm = this

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/module/code`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let filters = vm.searchFilterParam()
              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              vm.lastRouteParams = {
                loginUserToken: vm.$store.state.loginStore.lutk,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
                ...filters
              }
              return vm.lastRouteParams
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.mstGridRef.dataSource.page() - 1)
                * vm.$refs.mstGridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              // row 번호 셋팅
              row.rowNumber = response.total - rowNumber++
              row.searchName = vm.$refs.compoundTextRef.value
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },

        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
    computedDtlGridRows: function () {
      const vm = this

      if(!vm.uiData.cdGrupSelected) return []

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/module/code/${vm.uiData.cdGrupSelected}`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              return {
                loginUserToken: vm.$store.state.loginStore.lutk,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
              }
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.dtlGridRef.dataSource.page() - 1)
                * vm.$refs.dtlGridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              // row 번호 셋팅
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },

        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
  },
  methods: {
    cleanSearchDataEvent(ev) {
      const refs = this.$refs

      /**
       * 그룹코드/그룹코드명
       * */
      refs.compoundTextRef.value = ''

      this.commonOnChangeEvent()

    },
    searchFilterParam() {

      let searchFilterOptions = {}
      const refs = this.$refs

      // 그룹코드/그룹코드명
      const compoundText = refs.compoundTextRef.value
      if (!!compoundText) {searchFilterOptions.searchCompoundText = compoundText}

      return searchFilterOptions ?? undefined
    },
    onChangeMstGrid(ev) {

      let cellIdx = window.event.target.cellIndex
      let boolForg = window.event.target.className === 'forg' // 키워드 강조 span태그 클릭시에도 deatil로 이동
      if (!isNaN(cellIdx) || boolForg) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)

        this.uiData.cdGrupSelected = rowData.cdGrup
        this.uiData.isCdGrupSelected = true
      }
    },
    commonOnChangeEvent(e) {
      const mstGridRef = this.$refs.mstGridRef
      mstGridRef?.kendoWidget()?.dataSource?.query({
        sort: {},
        take: 10,
        page:  1,
        pageSize:  10,
      })

      this.uiData.isCdGrupSelected = false
      this.uiData.cdGrupSelected = ''
    },
    // 코드 grid datasource refresh
    fetchDtlGridDataSource(e) {
      const dtlGridRef = this.$refs.dtlGridRef
      dtlGridRef?.kendoWidget()?.dataSource?.query({
        sort: {},
        take: 10,
        page:  1,
        pageSize:  10,
      })
    },
  },
  data() {
    const vm = this

    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      uiData: {
        isCdGrupSelected: false,
        cdGrupSelected: '',
        cdGrupSaveMode: 'NEW',
        cdGrupSaveData: {},
        cdIdSaveMode: 'NEW',
        cdIdSaveData: {},
      },
      mstGridColumns: [
        {
          field: 'rowNumber',
          title: 'No.',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          sortable: false
        },
        {
          field: 'cdGrup',
          title: '그룹코드',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          template: function (dataItem) {
            return EtnersEfsUtil.emphasisGridSearchText(dataItem.searchName, dataItem.cdGrup)
          }
        },
        {
          field: 'cdGrupNm',
          title: '그룹코드명',
          width: '40%',
          headerAttributes: headerAttributes,
          template: function (dataItem) {
            return EtnersEfsUtil.emphasisGridSearchText(dataItem.searchName, dataItem.cdGrupNm)
          }
        },
        {
          field: 'seqNo',
          title: '조회순서',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'useYn',
          title: '사용여부',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          title: '수정',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          command: [
            {
              name: '수정',
              template: kendo.template('<a class=\'mid_btn k-grid-수정\'>수정</a>'),
              // template: kendo.template('<a class=\'mid_btn k-grid-수정\'><span class=\'k-icon k-i-edit\'></span>수정</a>'),
              click: function (e) {
                e.preventDefault()

                let selectedRow = e.currentTarget.closest('tr')
                let rowData = this.dataItem(selectedRow)

                vm.uiData.cdGrupSaveMode = 'EDIT'
                vm.uiData.cdGrupSaveData = rowData
                vm.$refs.cdGrupSaveWindowRef.widget().open()

              },
            },
          ],
          width: '10%',
        },
      ],
      dtlGridColumns: [
        {
          field: 'rowNumber',
          title: 'No.',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          sortable: false
        },
        {
          field: 'cdId',
          title: '코드',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'cdNm',
          title: '코드명',
          width: '40%',
          headerAttributes: headerAttributes,
        },
        {
          field: 'seqNo',
          title: '조회순서',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'useYn',
          title: '사용여부',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          title: '수정',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          command: [
            {
              name: '수정',
              template: kendo.template('<a class=\'mid_btn k-grid-수정\'>수정</a>'),
              click: function (e) {
                e.preventDefault()

                let selectedRow = e.currentTarget.closest('tr')
                let rowData = this.dataItem(selectedRow)


                vm.uiData.cdIdSaveMode = 'EDIT'
                vm.uiData.cdIdSaveData = Object.assign(rowData, { cdGrup: vm.uiData.cdGrupSelected })
                vm.$refs.cdIdSaveWindowRef.widget().open()

              },
            },
          ],
          width: '10%',
        },
      ]
    }
  },
}
</script>
